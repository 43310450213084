















































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import camelcaseKeys from 'camelcase-keys';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import Decimal from 'decimal.js';

import { constants } from '@/utils/constants';

import {
  lookupEmployee,
  lookupProduct,
} from '../../goods-received-notes/api/goods-received-notes-api';

import {
  getInventoryAssetJournalReportData,
} from '../api/inventory-reports-api';

@Component({
  name: 'InventoryAssetJournal',
})
export default class extends Vue {
  multiselectEmployeeList: Array<any> = [];
  multiselectEmployeeLoading = false;
  selectedEmployee: any|null = null;

  selectedProduct: any = null;

  productsOptions: Array<any> = [];
  isProductsOptionsLoadiung = false;

  reportDataDateInterval: any = null;

  reportLoading = false;

  inventoryOrderCriteriaOptions = [
    constants.inventoryOrderCriteria.denumire.name,
    constants.inventoryOrderCriteria.codProdus.name,
  ];

  selectedInvetoryOrderCriteriaName = constants.inventoryOrderCriteria.denumire.name;
  inventoryOrderCriteriaValue = constants.inventoryOrderCriteria.denumire.value;

  onSelectInventoryOrderCriteria(name) {
    switch (name) {
      case constants.inventoryOrderCriteria.codProdus.name:
        this.inventoryOrderCriteriaValue = constants.inventoryOrderCriteria.codProdus.value;
        break;
      default:
        this.inventoryOrderCriteriaValue = constants.inventoryOrderCriteria.denumire.value;
        break;
    }
    this.selectedInvetoryOrderCriteriaName = name;
  }

  get Decimal() {
    return Decimal;
  }

  formatDate(val, fmt) {
    return format(val, fmt);
  }

  tdClassProvider(val) {
    const retVal = ['text-right'];
    if (new Decimal(val).lt(0)) {
      retVal.push('table-warning');
    }
    return retVal;
  }

  documentTypeFormatter(val) {
    switch (val) {
      case 'TRA': return 'transfer';
      case 'FAC': return 'fact. emisă';
      case 'AVZ': return 'aviz emis';
      case 'FAF': return 'intrare (fact.)';
      case 'GRN': return 'NIR';
      case 'NIR': return 'NIR';
      case 'NP.': return 'notă de predare';
      case 'MRN': return 'Bon consum';
      case 'MTN': return 'Transfer';
      case 'BCC': return 'bon consum';
      case 'BCO': return 'Consum ob. inv.';
      default: return val;
    }
  }

  dateInterval = [startOfMonth(new Date()), endOfMonth(new Date())];
  datepickerShortcuts = [{
    text: 'Astăzi',
    onClick: () => [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    text: 'Ieri',
    onClick: () => [addDays(startOfDay(new Date()), -1), addDays(endOfDay(new Date()), -1)],
  },
  {
    text: 'Luna curentă',
    onClick: () => [startOfMonth(new Date()), endOfMonth(new Date())],
  }, {
    text: 'Luna trecută',
    onClick: () => [addMonths(startOfMonth(new Date()), -1), endOfMonth(addMonths(new Date(), -1))],
  }, {
    text: 'Ultimele 3 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -2), endOfMonth(new Date())],
  }, {
    text: 'Ultimele 6 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -5), endOfMonth(new Date())],
  }, {
    text: 'Anul curent',
    onClick: () => [startOfYear(new Date()), endOfYear(new Date())],
  }, {
    text: 'Anul trecut',
    onClick: () => [addYears(startOfYear(new Date()), -1), addYears(endOfYear(new Date()), -1)],
  }];

  startDate = startOfMonth(new Date());
  endDate = endOfMonth(new Date());
  inventory: any|null = null;
  excludeNoEntries = false;
  separateOnUnitPrice = false;
  perCompanyTotal = false;

  separateOnUnitPriceApplied = false;
  perCompanyTotalApplied = false;

  items: Array<any> = [];

  onPerCompanyTotalChange(value) {
    if (value) {
      this.inventory = null;
    }
  }

  async asyncSearchEmployees(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }

    this.multiselectEmployeeLoading = true;

    this.multiselectEmployeeList = camelcaseKeys(await lookupEmployee(query));

    this.multiselectEmployeeLoading = false;
    return Promise.resolve();
  }

  debouncedSearchEmployees = debounce(this.asyncSearchEmployees, 75, { maxWait: 150 });

  async asyncSearchProducts(query: string) {
    if (!query || query.length < 1) {
      return Promise.resolve();
    }
    this.isProductsOptionsLoadiung = true;

    this.productsOptions = camelcaseKeys(await lookupProduct(query));

    this.isProductsOptionsLoadiung = false;
    return Promise.resolve();
  }

  debouncedSearchProducts = debounce(this.asyncSearchProducts, 75, { maxWait: 150 });

  onReportIntervalChange(val) {
    [this.startDate, this.endDate] = val;
    this.dateInterval = val;
  }

  async onLoadReportData() {
    try {
      this.reportLoading = true;
      const reportData = await getInventoryAssetJournalReportData(
        formatISO(this.startDate),
        formatISO(this.endDate),
        this.selectedEmployee?.employeeId,
        this.selectedProduct?.productId,
        this.excludeNoEntries,
        this.separateOnUnitPrice,
        this.perCompanyTotal,
        this.inventoryOrderCriteriaValue,
      );

      this.reportDataDateInterval = format(this.startDate, 'dd/MM/yyy').concat(' - ').concat(format(this.endDate, 'dd/MM/yyy'));

      this.items = reportData;
      this.separateOnUnitPriceApplied = this.separateOnUnitPrice;
      this.perCompanyTotalApplied = this.perCompanyTotal;
    } finally {
      this.reportLoading = false;
    }
  }

  toISOString(date) {
    return new Date(date - date.getTimezoneOffset() * 60000).toISOString();
  }

  async onExcelExport() {
    window.location.href = `/api/inventory-report/inventory-asset-journal-excel?startDate=${encodeURIComponent(this.toISOString(this.startDate))}&endDate=${encodeURIComponent(this.toISOString(this.endDate))}&employeeId=${this.selectedEmployee?.employeeId}&excludeNoEntries=${this.excludeNoEntries}&separateOnUnitPrice=${this.separateOnUnitPrice}&productId=${this.selectedProduct?.productId}&perCompanyTotal=${this.perCompanyTotal}&inventoryOrderCriteria=${this.inventoryOrderCriteriaValue}`;
  }
}
